import cloneDeep from 'lodash.clonedeep'

export function getSection (sectionId) {
  return apiFetch(`/materials/sections/${sectionId}`)
}

export function getSections () {
  return apiFetch('/materials')
}

export function storeSection (data) {
  return apiFetch('/materials/sections', { method: 'POST', body: data })
}

export function updateSection (sectionId, data) {
  return apiFetch(`/materials/sections/${sectionId}`, { method: 'PATCH', body: data })
}

export function changeSectionOrder (sectionId, order) {
  return apiFetch(`/materials/sections/${sectionId}/order`, { method: 'PATCH', body: { order } })
}

export function deleteSection (sectionId) {
  return apiFetch(`/materials/sections/${sectionId}`, { method: 'DELETE' })
}

const prepareLessonData = data => {
  const copy = cloneDeep(data)
  if (copy.content) copy.content = JSON.stringify(copy.content)
  if (copy.description) copy.description = JSON.stringify(copy.description)
  copy.image_id = copy.image?.id
  copy.video_id = copy.video?.id
  copy.file_id = copy.file?.id
  if (copy.homework) copy.homework.file_id = copy.homework.file.id
  return copy
}

export function storeLesson (sectionId, data) {
  return apiFetch(`/materials/sections/${sectionId}/lessons`, { method: 'POST', body: prepareLessonData(data) })
}

export function updateLesson (lessonId, data) {
  return apiFetch(`/materials/lessons/${lessonId}`, { method: 'PATCH', body: prepareLessonData(data) })
}

export function getLesson (lessonId) {
  return apiFetch(`/materials/lessons/${lessonId}`)
}

export function changeLessonOrder (lessonId, order, newSectionId) {
  return apiFetch(`/materials/lessons/${lessonId}/order`, { method: 'PATCH', body: { order, section_id: newSectionId } })
}

export function deleteLesson (lessonId) {
  return apiFetch(`/materials/lessons/${lessonId}`, { method: 'DELETE' })
}

const prepareTestData = data => {
  const copy = cloneDeep(data)
  if (copy.questions) {
    copy.questions.forEach((question, qIndex) => {
      question.order = qIndex
      question.answers.forEach((answer, aIndex) => {
        answer.order = aIndex
      })
      question.attachments.forEach((attachment) => {
        if (attachment.entity_type === 'image_attachment' && attachment.entity) {
          attachment.entity.file_id = attachment.entity.file?.id
        }
      })

      if (question.type === 'text') {
        question.answers = null
      }
      if (question.type === 'one' && question.answers) {
        if (typeof question.right_idx === 'number') {
          question.answers.forEach((answer, idx) => {
            answer.right = idx === question.right_idx
          })
        } else {
          let lastRight = -1
          question.answers.forEach((answer, idx) => {
            if (answer.right) {
              lastRight = idx
            }
            answer.right = false
          })
          if (lastRight >= 0) {
            question.answers[lastRight].right = true
          }
        }
      }
    })
  }
  return copy
}

export function storeTest (sectionId, data) {
  return apiFetch(`/materials/sections/${sectionId}/tests`, { method: 'POST', body: prepareTestData(data) })
}

export function updateTest (testId, data) {
  return apiFetch(`/materials/lessons/tests/${testId}`, { method: 'PATCH', body: prepareTestData(data) })
}

const processTestData = data => {
  const copy = cloneDeep(data)
  if (copy.questions) {
    copy.questions.forEach((question) => {
      if (question.type === 'one') {
        question.answers.forEach((answer, idx) => {
          if (answer.right) {
            question.right_idx = idx
          }
        })
      }
    })
  }
  return copy
}

export function getTest (testId) {
  return apiFetch(`/materials/lessons/tests/${testId}`).then(processTestData)
}

export function shareMaterials (mentiId, data) {
  return apiFetch(`/menties/${mentiId}/materials/share`, { method: 'POST', body: data })
}

export function getLessonForMenti (lessonId) {
  return apiFetch(`/lessons/${lessonId}`)
}

export function sendTest (lessonID, data) {
  return apiFetch(`/lessons/${lessonID}/test`, { method: 'POST', body: data })
}

export function storeHomework (sectionId, data) {
  return apiFetch(`/materials/sections/${sectionId}/homeworks`, { method: 'POST', body: prepareLessonData(data) })
}

export function updateHomework (lessonId, data) {
  delete data.file
  return apiFetch(`/materials/lessons/homeworks/${lessonId}`, { method: 'PATCH', body: prepareLessonData(data) })
}

export function getHomework (lessonId) {
  return apiFetch(`/materials/lessons/homeworks/${lessonId}`)
}

export function sendHomework (lessonID, data) {
  data.file_id = data.file?.id
  delete data.file
  return apiFetch(`/lessons/${lessonID}/homework`, { method: 'POST', body: data })
}
